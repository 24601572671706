<template>
  <div>
    <click-to-edit
      v-model="scope.row.scale.period_result"
      placeholder="Внесите результат"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.period_result')"
      style="width: 100%"
      type="textarea"
      @input="saveElementPropChange(scope.row, 'scale', {period_result:$event})"
    ></click-to-edit>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-period-result-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
